@import "_variables.scss";

.main-content {
  width: 100%;

  .h-stack {
    width: 100%;
    top: 0px;
    padding: $padding-xxs $padding-medium;

    .title {
      font-size: 12px;
      color: #1c4e94;
      text-transform: uppercase;
      margin-bottom: 4px;
      font-weight: 300;
      padding-top: 30px;
    }
  }
}
