@import "_variables.scss";
.configuration-status-container {
  .configuration-status-title-text {
    display: flex;
    font-size: $font-size-small;
    font-family: $font-family-sfmono;
    font-weight: var(--chakra-fontWeights-bold);
    line-height: var(--chakra-lineHeights-8);
    letter-spacing: var(--chakra-space-px);
    margin-top: var(--chakra-space-16);
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    &.completed {
      color: $color-font-badge-success;
    }
  }
  .crt-header-styles{
    color:$color-toast-success-notification;
  }

  .top-style {
    display: grid;
    margin-top: var(--chakra-space-4);
    align-items: center;
    grid-template-columns: 1fr 1fr auto 1fr 1fr;
    margin-bottom: var(--chakra-space-14);
    .top-line {
      border-width: 1px;
      opacity: 1;
    }
    .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--chakra-space-40);
      height: var(--chakra-space-40);
      border-radius: 8px;
      box-shadow: 0px 4px 14px 0px $color-box-shadow;
      &.unmet {
        background-color: $color-bg-badge;
      }
      &.not_started {
        background-color: $color-bg-badge-warning;
      }
      &.in_progress {
        background-color: $color-bg-badge-warning;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.completed,
      &.prod_completed {
        color: $color-toast-success-notification;
        background-color: $color-bg-badge-success;
      }
      img {
        width: var(--chakra-space-20);
        height: var(--chakra-space-20);
      }
    }
  }
  .inprogress-line {
    border-color: $color-divider;
  }
  .completed-line {
    border-color: $color-toast-success-notification;
  }
}
