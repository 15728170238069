@import "_variables.scss";

.chakra-modal__content-container {
  z-index: $modal-z-index;
}
.app-modal-content {
  min-width: 500px;
  max-width: 900px;
  padding: 0px;
  border-radius: 15px;

  .app-modal-body-container {
    display: flex;
    flex-direction: column;
  }

  .app-modal-body {
    padding: var(--chakra-space-12-8);
  }

  .app-modal-split-image {
    flex-direction: row;
    img {
      height: 100%;
      object-fit: cover;
      min-width: 200px;
    }
  }

  .chakra-modal__close-btn {
    margin: var(--chakra-space-4);
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .app-modal-title {
    font-size: $font-size-l;
    font-weight: 600;
    padding: var(--chakra-space-8) var(--chakra-space-6) 0 var(--chakra-space-8);
  }

  footer {
    min-height: 70px;
    padding-top: 24px;
    margin-top: 20px;
    gap: 1rem;
  }
}
