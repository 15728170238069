@import "_variables.scss";
.container {
  min-width: 200px;
  max-width: 600px;
  background-color: $color-default-font-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: var(--chakra-space-4);
  padding-bottom: var(--chakra-space-8);
  .guided-tooltip-container {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: var(--chakra-space-6-4);
    padding: var(--chakra-space-12);
    color: $color-default-white;
    font-size: 14px;
    .title {
      font-weight: 700;
      font-size: $font-size-reg;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;

      .back-button {
        background-color: $color-default-font-color !important;
        padding: var(--chakra-space-8);
        color: $color-default-white;
        border: 1px solid $color-default-white;
        border-radius: 8px;
        font-weight: 700;
        font-size: $font-size-reg;
        min-width: 125px;
      }
      .next-button {
        background-color: $color-default-white;
        padding: var(--chakra-space-8);
        color: $color-default-font-color;
        min-width: 125px;
        border-radius: 8px;
        font-weight: 700;
        font-size: $font-size-reg;
        div {
          display: flex;
          column-gap: var(--chakra-space-3-2);
          font-weight: 700 !important;
          font-size: $font-size-reg !important;
        }
        img {
          transform: rotate(270deg);
        }
      }
    }
    .close-icon {
      display: block;
      position: absolute;
      top: 10px;
      right: 40px;
      cursor: pointer;
    }
  }
  .progress-bar {
    background-color: $color-font-card;
  }
}
