@import "_variables.scss";
.percentage-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.input-heading {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1rem;
  z-index: 1;
  position: relative;
  color: $color-font-card;
  justify-content: left;
  display: flex;
  margin-left: -7.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  letter-spacing: 0.014rem;
}

.input-box {
  display: flex;
  align-items: left;
  border: 1px solid $color-description-txt;
  border-radius: 0.8rem;
  padding: 0.8rem;
  width: 11rem;
  height: 4.3rem;
  position: relative;
  font-size: 1.4rem;

  input {
    border: none;
    outline: none;
    width: 25%;
    padding: 0;
    text-align: left;
    background-color: $color-bg-default;
  }

  span {
    margin-left: 4px;
  }
}
