@import "_variables.scss";
.avatar-class {
  svg {
    path {
      fill: $color-default-black;
    }
  }
  background-color: $color-default-white;
}
.avatar-class-circle {
  svg {
    path {
      fill: $color-default-black;
    }
  }
  background-color: $color-default-white;
  outline: 0.2rem solid $color-nav-selected;
}
.avatar-square-box {
  width: 4rem;
  height: 4rem;
  background-color: $color-default-white;
  outline: 0.2rem solid $color-nav-selected;
  display: flex;
  justify-content: center;
  align-items: center;
}
