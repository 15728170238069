// Screen Sizes for mobile devices
$brakpoint-mobile: 600px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

$family-mono: 'Roboto Mono', monospace;

@mixin mobile {
  @media screen and (max-width: $brakpoint-mobile) {
    @content;
  }
}

@mixin card-container() {
  padding: var(--chakra-space-12-8);
  background: $color-default-white;
  box-shadow: 0px 4px 14px 0px $box-shadow-status-sidebar;
  border-radius: 16px;
  height: fit-content;

  @media screen and (max-width: $breakpoint-mobile-desktop) {
    padding: var(--chakra-space-6-4);
  }
}

$default-multiplier: 1;
$base-padding: 4px;
$base-margin: 4px;

// Mixin for Dynamic Padding and Margin
@mixin dyn-padding(
  $side,
  $value: $base-padding,
  $multiplier: $default-multiplier
) {
  @include _dynamic-spacing(padding, $side, $value, $multiplier);
}

@mixin dyn-margin(
  $side,
  $value: $base-padding,
  $multiplier: $default-multiplier
) {
  @include _dynamic-spacing(margin, $side, $value, $multiplier);
}

@mixin no-margin() {
  @include _dynamic-spacing(margin, all, $base-margin, 0);
}

@mixin no-padding() {
  @include _dynamic-spacing(padding, all, $base-padding, 0);
}

// Mixin for Dynamic Padding and Margin
@mixin _dynamic-spacing($property, $side, $value, $multiplier) {
  $adjusted-value: $value * $multiplier;

  @if $side == "all" {
    #{$property}: $adjusted-value;
  } @else {
    @if $side == "horizontal" {
      #{$property}-left: $adjusted-value;
      #{$property}-right: $adjusted-value;
    } @else if $side == "vertical" {
      #{$property}-top: $adjusted-value;
      #{$property}-bottom: $adjusted-value;
    } @else {
      #{$property}-#{$side}: $adjusted-value;
    }
  }
}

@mixin inline-code() {
  .inline-code {
    border-radius: $border-radius-xs;
    background-color: $color-inline-code-background;
    padding: var(--chakra-space-1-6);
    color: $color-inline-code-text;
    font-family: $family-mono;
  }
}

@mixin prismic_list_margin() {
  margin-left: var(--chakra-space-6-4);
  margin-bottom: var(--chakra-space-9-6);
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: $breakpoint-mobile-desktop) {
    margin-left: 0 !important;
  }

  > li {
    margin-top: 0;
    margin-left: var(--chakra-space-9-6);
    margin-bottom: var(--chakra-space-6-4);
    &:last-child {
      margin-bottom: 0
    }
  }
}
