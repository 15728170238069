@import "_variables.scss";

.zoom-out-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 998;
  top: $app-header-height;
  width: 100vw;
  height: calc(100vh - $app-header-height);
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;
  opacity: 1;

  .zoom-out-icon {
    top: var(--chakra-space-4);
    right: 10%;
    position: absolute;
    border-radius: 100%;
    padding: var(--chakra-space-4);
    background: white;
    width: var(--chakra-space-13);
    height: var(--chakra-space-13);
    cursor: pointer;
    z-index: 1000;
  }
  .wrapper {
    :global(.img-mapper-map) {
      cursor: pointer;
    }
    svg {
      visibility: hidden;
    }
    img {
      height: calc(100vh - $app-header-height);
    }
  }
}
.zoom-in-div {
  position: relative;
  width: 80rem;
  :global(.tooltip-icon svg) {
    visibility: hidden;
  }
  .zoom-in-icon-div {
    position: absolute;
    cursor: pointer;
    height: var(--chakra-space-16);
    width: var(--chakra-space-16);
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 50%;
    background: $color-default-white;
    z-index: 2;
    &:hover {
      background-color: $color-sidebar-hover-bg;
    }
    .zoom-in-icon {
      width: var(--chakra-space-7);
      height: var(--chakra-space-7);
      transform: rotate(90deg);
      stroke-width: 0.6;
      position: absolute;
      bottom: 12px;
      right: 10px;
    }
  }
  :global(.img-mapper-map) {
    cursor: pointer;
  }
}
