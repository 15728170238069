@import "_variables.scss";
.calendar-overlay {
  z-index: 1000;
  background-color: $color-default-white;
}
.input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border: 0.1rem solid $calendar-input-border;
  border-radius: 0.8rem;
  height: 4.8rem;

  &.date-picker-focus {
    border: 0.1rem solid $color-link-hover-color;
  }

  &.date-picker-error {
    border: 0.1rem solid $color-default-danger;
  }

  &.date-picker-disabled {
    pointer-events: none;
  }
  &.date-picker-small {
    height: 4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  &.date-picker-small .calendar-icon {
    margin-top: -10px;
  }
}
.datepicker-input {
  padding-right: 0rem;
  background-color: $color-default-white;
  border-radius: 0.8rem;
  padding-left: 1.6rem;
  margin-right: 2rem;
  width: 75%;
  outline: none;
}

.calendar-symbol {
  margin-right: var(--chakra-space-4);
  margin-top: var(--chakra-space-4);
  cursor: pointer;
}
.error-text-calendar {
  color: $color-default-danger;
}

.react-calendar {
  border: none;
  font-weight: bold;
  font-size: $font-size-small;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__month-view__weekdays {
  border-bottom: 0.1rem solid $color-description-txt;
  margin-left: var(--chakra-space-4);
  margin-right: var(--chakra-space-4);
}
.react-calendar__month-view__days__day--weekend {
  color: $color-default-black;
  font-family: inherit;
}
.react-calendar__tile--now {
  background-color: $color-default-white;
  color: $color-default-black;
}
.react-calendar__tile {
  padding: var(--chakra-space-6-4);
  font-size: $font-size-small;
}
button:hover {
  background-color: transparent;
}
.react-calendar__tile--hasActive {
  background-color: transparent;
  abbr {
    background-color: $color-link-hover-color;
    padding: var(--chakra-space-4) var(--chakra-space-6);
    border-radius: $border-radius-large;
    color: $color-default-white;
  }
}

.react-calendar__year-view__months__month:hover {
  background-color: transparent;
}

.react-calendar__tile--active {
  flex: 0 0 14.2857%;
  overflow: hidden;
  margin-inline-end: 0px;
  color: $color-default-white;
  border-radius: 50%;
  background-color: $color-link-hover-color;
}

.react-calendar__month-view__days__day:hover {
  background: $color-bg-badge !important;
  border-radius: 50%;
}
.react-calendar__tile--active:hover {
  background-color: $color-link-hover-color !important;
  color: $color-default-white !important;
}
.react-calendar__decade-view__years__year {
  padding: 0 !important;
  margin: var(--chakra-space-6) !important;
  border-radius: $border-radius-xlarge;
  height: 36px;
  flex: 1 0 80px !important;
}
.react-calendar__decade-view__years__year:hover {
  padding: 0 !important;
  margin: var(--chakra-space-6) !important;
  border-radius: $border-radius-xlarge;
  height: 36px;
  flex: 1 0 80px !important;
  background-color: $color-bg-badge !important;
  color: $color-default-font-color;
}
.react-calendar__decade-view__years__year:last-child {
  padding: 0 !important;
  margin: var(--chakra-space-6) !important;
  border-radius: $border-radius-xlarge;
  height: 36px;
  flex: 0 0 80px !important;
}
.react-calendar__month-view__days .react-calendar__year-view__months {
  .react-calendar__tile--hasActive:hover {
    background-color: transparent;
    abbr {
      background-color: $color-link-hover-color !important;
      padding: var(--chakra-space-4) var(--chakra-space-6);
      border-radius: $border-radius-large;
      color: $color-default-white !important;
    }
  }
}

.react-calendar__year-view__months__month:hover {
  abbr {
    background-color: $tag-notstarted-bg-color;
    padding: var(--chakra-space-4) var(--chakra-space-6);
    border-radius: $border-radius-large;          
    color: $tag-notstarted-text-color;
  }
}
abbr[title] {
  text-decoration: none;
}
.button-placing {
  display: flex;
  position: relative;
  right: 0.5rem;
  gap: $gap-xs;
  justify-content: flex-end;
  margin-bottom: var(--chakra-space-4);
  .app-btn-reg-secondary-transparent {
    padding: var(--chakra-space-3-2) var(--chakra-space-2-4) !important;
    width: 95px;
  }
  .app-btn-inverse-secondary{
    padding: var(--chakra-space-3-2) var(--chakra-space-2-4) !important;
    width: 95px;
  }
}
.react-calendar__viewContainer:hover {
  background-color: transparent;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  background: transparent;
  color: $color-default-font-color;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: $font-size-med;
  font-weight: bold;
}
.react-calendar__decade-view__years {
  .react-calendar__tile--hasActive {
    background-color: $color-link-hover-color !important;
    padding: 0 !important;
    margin: var(--chakra-space-6) !important;
    border-radius: $border-radius-xlarge;
    height: 36px;
    flex: 0 0 80px !important;
    color: $color-default-white !important;
  }
}

.react-calendar__navigation__prev-button {
  font-size: $font-size-l !important;
  font-weight: normal !important;
}
.react-calendar__navigation__next-button {
  font-size: $font-size-l !important;
  font-weight: normal !important;
}
.react-calendar__century-view__decades {
  .react-calendar__tile--hasActive {
    background-color: $color-link-hover-color !important;
    padding: 0 !important;
    margin: var(--chakra-space-6) !important;
    border-radius: $border-radius-xlarge;
    height: auto;
    flex: 0 0 84px !important;
    color: $color-default-white !important;
  }
}