@import "_variables.scss";

.ic-summary-selection-container {
  background: $color-surface-blue-light;
  padding: var(--chakra-space-13);
  margin-top: 0;

  h3 {
    font-size: $font-size-l;
    font-family: $font-family-semi-bold;
    margin-bottom: var(--chakra-space-8);
  }

  h6 {
    font-size: $font-size-xs;
    font-family: $font-family-sfmono !important;
  }
  .feature-list-icon {
    color: $color-gradient-light-orange;
  }
}
