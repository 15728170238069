@import "_variables.scss";

.tooltip-hover-content {
  position: absolute;
  background-color: $color-default-font-color;
  color: $color-default-white;
  padding: var(--chakra-space-3-2);
  border-radius: 0.4rem;
  z-index: 999;
  box-shadow: 0.2rem solid $color-default-black;
  border: 0.2rem solid $color-default-font-color;
  box-shadow: 0 0 0.8rem $color-box-shadow-tooltip;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.2rem;
  font-weight: $font-weight-semi-bold;
  height: auto;
  font-size: $font-size-xs;
  
}
