@import "_variables.scss";

.chakra-modal__body {
  overflow: visible;
}

.sub-title {
  font-size: var(--chakra-fontSizes-2xl);
  line-height: var(--chakra-lineHeights-8);
  color: $color-version-subtext;
}
