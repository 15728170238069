@import "_variables.scss";

.genralized-tooltip-container {
  padding: 0.8rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  color: $color-default-white;
  max-width: 250px;
  background-color: $color-default-font-color;
  --popper-arrow-bg: #26292e;
  box-shadow: 0px 4px 14px 0px $color-box-shadow;
}
