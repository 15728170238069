@import "_variables.scss";

.badge-main-container {
  .tag-default {
    font-size: $font-size-small;
    font-weight: 500;
    font-family: $font-family-sfmono-semi-bold;
    text-transform: uppercase;
    padding: var(--chakra-space-3-2) var(--chakra-space-8);
    background-color: $color-bg-badge;
    color: $color-font-card;
    justify-content: center;
    width: max-content;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      line-height: 20px;
    }
    white-space: normal;
    &.tag-success {
      color: $color-font-badge-success !important;
      background-color: $color-bg-badge-success !important;
    }
    &.tag-error {
      color: $color-default-danger !important;
      background-color: $color-bg-badge-error !important;
    }
    &.tag-warning {
      color: $color-font-card !important;
      background-color: $color-bg-badge-warning !important;
    }
    .tag-image {
      margin-right: var(--chakra-space-2);
      width: var(--chakra-space-6);
      height: var(--chakra-space-6);
    }
    &.tag-primary {
      color: $color-link-hover-color !important;
      background-color: $color-btn-secondary-hover !important;
    }
    .tag-label-style {
      line-height: $line-height-small;
      font-family: $font-family-sfmono;
    }
  }

  .tag-label-pink {
    padding: var(--chakra-space-6);
    color: $tag-label-pink;
  }
  .tag-styles-pink {
    font-size: var(--chakra-space-6);
    background-color: $tag-style-pink;
  }

  .tag-label-gray {
    padding: var(--chakra-space-6);
    color: $color-bg-badge;
  }
  .tag-styles-gray {
    font-size: var(--chakra-space-6);
    background-color: $tag-style-gray;
  }
  .tag-label-cyan {
    padding: var(--chakra-space-6);
    color: $tag-label-cyan;
  }
  .tag-styles-cyan {
    font-size: var(--chakra-space-6);
    background-color: $tag-style-cyan;
  }
  .tag-label-yellow {
    padding: var(--chakra-space-6);
    color: $tag-label-yellow;
  }
  .tag-styles-yellow {
    font-size: var(--chakra-space-6);
    background-color: $tag-style-yellow;
  }
  .tag-icon-styles {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--chakra-space-5);
  }

  .icon-circle-cyan {
    border: 2px solid $tag-label-cyan;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: $tag-label-cyan;
    width: var(--chakra-space-9);
    height: var(--chakra-space-9);
  }
  .icon-color-cyan {
    color: white;
  }
  .icon-circle-yellow {
    background-color: $tag-style-yellow;
    border: 2px solid $tag-label-cyan;
    border-radius: 50%;
    justify-content: center;
  }
  .icon-circle-gray {
    border: 2px solid $tag-style-gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: $tag-style-gray;
    width: var(--chakra-space-9);
    height: var(--chakra-space-9);
  }
  .tag-icon-styling-close {
    margin-top: var(--chakra-space-2);
    height: var(--chakra-space-4);
    color: $color-default-black;
  }
  .tag-icon-styling-completed {
    margin-top: var(--chakra-space-2);
    height: var(--chakra-space-4);
    color: $color-default-white;
  }
  .tag-icon-styling-error {
    height: var(--chakra-space-8);
    width: var(--chakra-space-8);
    color: $color-red-toast-notification;
  }
  .tag-icon-styling-In-Progress {
    height: var(--chakra-space-8);
    width: var(--chakra-space-8);
    color: $color-yellow-toast-notification;
  }
}
