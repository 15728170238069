@import "_variables.scss";

.top-dashboard-footer {
  position: absolute;
  bottom: 0;
  left: 32px;
  right: 0;
  background-color: $color-footer-background;
  padding: var(--chakra-space-1-6) 0;
  align-items: center;
  z-index: 11;
  .footer-text {
    text-align: center;
    font-size: $font-size-xs;
    font-family: $font-family-regular;
    font-weight: $font-weight-regular;
  }
}
