@import "_variables.scss";

.static-text {
  h2 {
    font-size: $font-size-l;
    font-weight: 600;
    font-family: $font-family-bold;
  }
  .chakra-text {
    font-family: $font-family-regular;
    font-size: $font-size-reg;
    line-height: 24px;
    font-weight: 400;
  }
}
