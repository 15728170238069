@import "_variables.scss";
@import url("react-clock/dist/Clock.css");
@import url("react-time-picker/dist/TimePicker.css");

.time-field-wrapper {
  height: 48px;
  border-radius: $border-radius-medium;
  display: "flex";
  align-items: center;
  border: 1px solid $color-border;
  font-size: $font-size-reg;
  line-height: $line-height-medium;
  background-color: transparent;
  width: 160px;
  .time-picker-input-container {
    height: 100%;
  }
  .react-time-picker__wrapper {
    border: none;
  }
}

.time-field-wrapper:disabled {
  border: 1px solid $color-description-txt;
}

.react-time-picker {
  width: 100%;
  height: 100%;
  padding: var(--chakra-space-3);
  font-family: $font-family-regular;
  font-size: $font-size-small;
  color: $color-default-black;
  letter-spacing: 0px;
  background-color: transparent;

  :disabled {
    color: $color-border;
  }
}

.react-time-picker--disabled {
  .react-time-picker__inputGroup__leadingZero {
    color: $color-border;
  }
}

.react-time-picker__inputGroup__second,
.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__minute {
  width: 25px !important;
}

.react-time-picker__inputGroup__input:disabled::placeholder {
  color: $color-border;
  opacity: 1; /* Firefox */
}

.react-time-picker__inputGroup__input:disabled::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: $color-border;
}

.react-time-picker__inputGroup__input[disabled]
  ~ .react-time-picker__inputGroup__divider {
  color: $color-border;
}

.react-time-picker__button {
  pointer-events: none;
}
.time-picker-dropdown {
  width: 197px;
  box-shadow: $box-shadow-default;
  .time-picker-list {
    height: 300px;
    overflow: auto;
    padding: var(--chakra-space-3);
    cursor: pointer;
    & .selected {
      background: $color-surface-text-blue;
      color: $color-default-white;
      border-radius: $border-radius-xs;
    }
    .time-picker-container:hover {
      background: $color-footer-background;
      border-radius: $border-radius-xs;
    }
  }
}
