@import "_variables.scss";

.content-catalogue-container {
  .app-card-container {
    padding: 20px 0px;
  }
  h3 {
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
  }

  .content-catalogue-header {
    border-bottom: 1px solid $color-link-hover-color;
    padding-bottom: 20px;
  }

  .content-catalogue-body {
    .content-catalogue {
      border-right: 1px solid $color-border;
      margin-bottom: 20px;
      &.remove-border {
        border-right: 0px !important;
      }
    }
  }

  .app-btn-link {
    text-decoration: none;
    padding-left: 0px;
    svg {
      margin-top: 3px;
    }
  }
}
