@import "_variables.scss";
.progress-bar-background {
  background-color: $color-bg-badge;
  border-radius: 1.5rem;
  height: 1.5rem;
  &.progress-bar-hover:hover {
    height: 3rem;
    transition: 0.5s;
    cursor: pointer;
  }
  &.progress-bar-hover:hover div {
    background-color: $color-progress-bar-disabled-bgcolor;
    border-radius: 1.5rem;
  }
  &.progress-bar-background-focus {
    border: 0.1rem solid $color-link-color;
  }
  &.progress-bar-background-disabled {
    background-color: $color-bg-badge;
  }
  &.progress-bar-background div {
    background-color: $color-nav-selected;
    border: 0.2rem solid $color-default-white;
    border-radius: 1.5rem;
  }
  &.progress-bar-background-disabled div {
    background-color: $color-progress-bar-disabled-bgcolor;
    border: 0.2rem solid $color-default-white;
    border-radius: 1.5rem;
  }
}
.hover-text {
  position: absolute;
  top: 0;
  padding-left: 1rem;
  padding-top: 0.5rem;

  color: $color-default-black;
}
.progress-bar-width {
  width: 50%;
}
