@import "_variables.scss";
@import "mixins.scss";

$family-mono: 'Roboto Mono', monospace;

@mixin markdown-link() {
  font-size: $font-size-reg;
  font-weight: $font-weight-bold;
  line-height: $line-height-xs;
  letter-spacing: 0.02em;
  text-align: left;
  color: $color-surface-text-blue;
  &:hover {
    text-decoration: underline;
    color: $color-btn-link-hover;
  }
}

section:has(> .prismic-paragraph) + section.md-section:has(> ul:first-child),
section:has(> .prismic-paragraph) + section.md-section:has(> ol:first-child) {
  margin-top: calc(-1 * var(--chakra-space-3-2));
}

.md-section > div:not(:only-child):not([class]):has(> .md-table-container),
.md-section > div:not(:only-child)[class=""]:has(> .md-table-container) {
  margin-top: var(--chakra-space-12-8);
}
.md-section {
  > .md-table-container {
    margin-top: var(--chakra-space-3-2);
    &:first-child {
      border-top-left-radius: 16px;
      border-style: solid;
      border-color: $fast-gray-6;
      outline: solid 0.5px $fast-gray-6;
    }
    &:last-child {
      border-top-right-radius: 16px;
      border-style: solid;
      border-color: $fast-gray-6;
      outline: solid 0.5px $fast-gray-6;
    }
  }
  * + .md-table-container {
    margin-top: var(--chakra-space-12-8);
  }
}

div:only-child:has(> .md-table-container) {
  margin-top: var(--chakra-space-3-2);
}
.markdown-table {
  border-collapse: separate;
  border-spacing: 0;
  thead {
    background-color: $fast-gray-5;
    border-bottom: 0.5px solid $fast-gray-6;
    border: 0.5px solid $fast-gray-6;
    tr {
      th {
        padding: 10px;
        text-align: left;
        min-height: 44px;
        &:first-child {
          border-top-left-radius: 16px;
        }
        &:last-child {
          border-top-right-radius: 16px;
        }
        border-top: 0.5px;
        border-right: 0.5px;
        border-bottom: 0.5px;
        border-left: 0.5px;
        border-style: solid;
        border-color: $fast-gray-6;
      }
    }
  }
  tbody > tr > td {
    border-top: 0px;
    border-right: 0.5px;
    border-bottom: 0.5px;
    border-left: 0.5px;
    border-style: solid;
    border-color: $fast-gray-6;
    line-height: $line-height-medium;
    padding: var(--chakra-space-4);
    vertical-align: text-top;
    * {
      font-size: $font-size-reg;
      font-weight: $font-weight-regular;
      line-height: $line-height-medium;
      letter-spacing: 0.01em;
      text-align: left;
    }
    ul {
      margin-left: var(--chakra-space-9-6) !important;
    }
    ul > li,
    ol > li {
      margin-left: var(--chakra-space-9-6) !important;
    }

    .markdown-link {
      @include markdown-link();
    }
    b {
      font-weight: $font-weight-bold;
    }
  }
}
/* Apply CSS to the first row of the table when the table does not have a thead */
.markdown-table:not(:has(thead)) > tbody > tr:first-child {
  border-top: 0.5px solid $color-font-card;
}

.example {
  margin-top: var(--chakra-space-3-2);
  margin-bottom: var(--chakra-space-6-4);
}
.example-bm {
  margin-bottom: var(--chakra-space-6-4);
}
.example-tm {
  margin-top: var(--chakra-space-6-4);
}

.markdown-link {
  @include markdown-link();
}

ul.markdown-list {
  margin-left: var(--chakra-space-9-6);
  margin-top: var(--chakra-space-6-4);
  margin-bottom: var(--chakra-space-6-4);
  @include prismic_list_margin();
}

ol.markdown-list {
  @include prismic_list_margin();
}

ul.markdown-list > li,
ol.markdown-list > li {
  font-weight: $font-weight-regular;
  color: $color-font-card;
  font-size: $font-size-reg;
  line-height: $line-height-medium;
  p {
    margin-bottom: 0px;
  }
  ul.markdown-list {
    margin-left: 0px !important;
    margin-top: var(--chakra-space-6-4) !important;
    li {
      margin-left: var(--chakra-space-9-6) !important;
    }
  }
  pre {
    margin: var(--chakra-space-9-6) 0;
  }
}

ul.markdown-list > li {
  list-style-type: disc;
  ul.markdown-list > li {
    list-style-type: circle;
    ul.markdown-list > li {
      list-style-type: square;
      ul.markdown-list > li {
        list-style-type: disc;
      }
    }
  }
}

.markdown-heading-two {
  font-family: $font-family-bold;
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-xlarge;
  text-align: left;
  margin-bottom: var(--chakra-space-3-2);
}

.markdown-code {
  .code-container {
    .cm-theme {
      .cm-editor {
        border-top-left-radius: var(--chakra-space-3-2);
        border-top-right-radius: var(--chakra-space-3-2);
      }
    }
  }
}
.markdown-single-line-code {
  border-radius: $border-radius-xs;
  background-color: $color-inline-code-background;
  padding: var(--chakra-space-1-6);
  color: $color-inline-code-text !important;
  font-family: $family-mono;
}

.markdown-divider {
  border: 0.5px solid $color-border !important;
  margin: var(--chakra-space-12-8) 0px;
}

.markdown-image {
  border-radius: var(--chakra-space-3-2);
  border: 1px solid $color-font-card;
  margin-top: var(--chakra-space-12-8);
  margin-bottom: var(--chakra-space-12-8);
}

.markdown-paragraph {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: $font-weight-regular;
  line-height: $line-height-medium;
  color: $color-font-card;
  :last-child {
    margin-bottom: 0;
  }

  @include inline-code();
  .inline-icon {
    display: inline;
    vertical-align: middle;
  }
}