@import "_variables.scss";
.alert-container {
  background-color: $color-default-white;
  box-shadow: 0rem 0.4rem 1.4rem 0rem $color-toast-box-shadow;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}
.alert-header {
  display: flex;
  flex-direction: row;
}
.alert-icon {
  height: 3rem;
  width: 10%;
  margin-top: 1.5rem;
  margin-left: 1rem;
}
.custom-icon {
  width: 5rem;
  height: 6.6rem;

  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: $color-bg-default;
}
.box-padding {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-left: 1.5rem;
}
.custom-icon-yellow {
  background-color: $color-yellow-toast-notification;
  width: 5rem;
  height: 6.6rem;
  border: 0.2rem solid $color-yellow-toast-notification;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link-toast-icon {
  color: $color-btn-link-hover;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 1.6rem;
  padding-top: 0.3rem;
}
.vertical-line {
  border: 0.1rem solid $color-border;
  height: 3rem;
}
.close-button-toast {
  padding-left: 0.1rem;
  margin-left: 1.6px;
  padding-top: 0.6rem;
  align-self: flex-start;
  position: relative;
  right: calc(var(--chakra-space-1) * -1);
  top: 0px;
}
.alert-header-second {
  display: flex;
  flex-direction: row;
  margin-right: 3rem;
}
.alert-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.icon-circle-style {
  margin-left: 6px;
  margin-top: 2px;
}
.icon-circle-style-1 {
  margin-left: 6px;
  margin-top: 2px;
  color: $color-yellow-toast-notification;
}
.green-icon {
  color: $color-toast-success-notification;
}
.yellow-icon {
  color: $color-yellow-toast-notification;
}
.blue-icon {
  color: $color-btn-link-hover;
}
.red-icon {
  color: $color-red-toast-notification;
}
.toast-alert-containers {
  width: 45%;
}
