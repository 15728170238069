@import "_variables.scss";

.duration-input-modal {
    .app-modal-content {
        .app-modal-body-container {
            .chakra-modal__body {
                overflow-y: visible;
            }
        }
    }
  }