@import "_variables.scss";

.custom-table-container {
  .custom-table-header {
    :first-child {
      border-radius: 8px 0 0 0;
    }
    :last-child {
      border-radius: 0 8px 0 0;
    }
    .custom-table-header-data {
      border-bottom: 0.1rem solid $color-border;
      column-gap: 1rem;
      padding: 1.5rem;
      color: $color-default-black;
      background-color: $color-bg-badge;
      .custom-table-header-data-item {
        display: flex;
        column-gap: 0.7rem;
        align-items: center;
        font-size: $font-size-reg;
        font-weight: 500;
        line-height: $line-height-medium;
        letter-spacing: 0em;
        min-width: var(--chakra-sizes-8);
        height: 2rem;
        .down-arrow {
          transform: rotate(180deg) !important;
        }
      }
    }
  }
  .custom-table-data-row {
    font-weight: 400;
    font-size: $font-size-small;
    line-height: $line-height-small;
    padding: var(--chakra-space-6);
    letter-spacing: 0.01em;
    border-bottom: 1px solid $color-border;
    max-width: 250px;
    direction: ltr;
    margin: auto;
    text-align: left;
    word-break: break-word;
    white-space: pre-line;
    -ms-word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    .custom-accordion-item-button {
      &:hover {
        background: none;
      }
    }
    &.row-selected {
      background-color: $color-surface-blue-light;
      color: $color-surface-text-blue;
    }
  }
  .is-last-element {
    text-align: right;
  }
  .accordion-content {
    padding: var(--chakra-sizes-6);
    p.chakra-text {
      min-width: var(--chakra-sizes-60);
      word-break: break-all;
      white-space: break-spaces;
    }
    .app-form-field-container {
      margin-bottom: var(--chakra-sizes-6);
    }
    .inprogress-heading {
      font-size: $font-size-xs;
      font-family: $font-family-sfmono !important;
      text-transform: uppercase;
    }
  }
  .row-expanded {
    .custom-table-data-row {
      border-bottom: 1px solid $color-nav-selected;
    }
  }
  .acc-row-expanded {
    border-bottom: 1px solid $color-border;
  }
}
.pagination-panel {
  border-top: 1px solid $color-font-card;
  margin-top: 32px;
  font-size: $font-size-reg;
  padding: {
    top: 20px;
    bottom: 20px;
  }
  .pag-nav-btn {
    border-color: $color-link-hover-color;
    color: $color-font-card;
    &:hover {
      background-color: $color-link-hover-color;
      color: $color-default-white;
    }
  }
  .pagination-number-panel {
    li {
      float: left;
      margin-top: 0;
      padding: 8px 8px 4px 8px;
      cursor: pointer;
      margin-right: 4px;
    }
    li:hover {
      border-bottom: 2px solid $color-gradient-light-orange;
    }
    li.active {
      font-weight: bold;
      border-bottom: 2px solid $color-gradient-light-orange;
    }
  }
}

.ct-multiselect {
  margin-top: var(--chakra-space-12-8);
  margin-bottom: var(--chakra-space-12-8);
  .fx-selected-values-container {
    .selected-options {
      .tag-container {
        margin-top: var(--chakra-space-6-4);
      }
      .clear {
        margin-top: var(--chakra-space-6-4) !important;
      }
    }
  }
}
.data-found-message {
  display: flex;
  justify-content: center;
  padding: var(--chakra-space-4);
  font-size: $font-size-reg;
}
