@import "_variables.scss";

.item-link:focus-visible {
  outline: none;
}

.main-container-item {
  background-color: transparent;

  padding: $padding-small $padding-medium;

  border-left-color: none;
  border-top-color: none;
  border-bottom-color: none;
  border-right-color: none;

  border-left-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  cursor: pointer;

  &.side-menu-open {
    height: auto;
  }

  &.side-menu-closed {
    height: 45px;
    justify-content: center;
  }

  .item-link {
    flex: 1;
  }

  .item-title {
    color: $color-default-font-color;
    font-family: $font-family-regular;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: $line-height-small;
    letter-spacing: 0.01em;
    text-align: left;
    word-break: break-word;
    flex: 1;

    a {
      outline: none;
    }

    &.enable-selection-borders-first-two-column {
      font-weight: $font-weight-semi-bold;
    }

    &.is-home-item {
      justify-content: space-between;
    }
  }

  .icon-chevron-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    &:hover {
      border: 1px solid $color-border-hover
    }
  }

  .icon-left {
    margin-bottom: var(--chakra-space-1);
  }

  &.enable-hover-color {
    background-color: $color-bg-badge;
  }

  &.enable-back-color {
    background-color: $color-sidebar-hover-bg;
    font-weight: var(--chakra-fontWeights-semibold);
  }

  &.enable-selection-borders-first-two-column {
    border-left-color: $color-link-hover-color;
    border-top-color: $color-link-hover-color;
    border-bottom-color: $color-link-hover-color;
    border-right-color: $color-default-white;

    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;

    width: calc(100% + 1px);
  }

  &.disable-selection-borders-first-two-column {
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;

    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-right-width: 0px;

    width: 100%;
  }
}
