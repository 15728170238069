@import "_variables.scss";

.rjsf_form {
  overflow: auto;
  max-height: var(--chakra-sizes-4xl);
  margin-top: var(--chakra-space-8);
  padding-right: var(--chakra-space-8);
  padding-left: var(--chakra-space-2);
  .chakra-form__label {
    font-size: var(--chakra-fontSizes-2xl);
    .chakra-form__required-indicator {
      font-weight: var(--chakra-fontWeights-extrabold);
    }
  }
  .chakra-input {
    height: var(--chakra-space-16);
    font-size: var(--chakra-fontSizes-2xl);
  }
  h5 {
    font-size: $font-size-med;
  }
}

.fxt-select-menu-portal {
  z-index: calc($modal-z-index + 1);
  div {
    font-size: var(--chakra-fontSizes-2xl);
  }
}
