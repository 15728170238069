@import "_variables.scss";

.app-number-input-container {
  .chakra-numberinput__field {
    height: 48px;
    border: 1px solid $color-form-field-border;
    font-size: $font-size-reg;
    border-radius: 8px;
    padding: 0px 1.6rem;
  }
   .chakra-numberinput__field:hover{
    border: 1px solid $color-description-txt;
    border-radius: $border-radius-small;
     }
}