@import "_variables.scss";

.tooltip-main-container {
  display: inline-block;
  position: relative;

  .tooltip-content-text {
    font-size: $font-size-xs;
  }
  .close-icon {
    color: $color-default-white;
  }
  &.product-summary-feature-tooltip {
    position: unset;
    .tooltip-content-wrapper {
      width: 275px;
      &.right {
        left: 28%;
      }
    }
  }
}

.tooltip-content-wrapper {
  position: absolute;
  background-color: $color-default-font-color;
  color: $color-default-white;
  border: 1px solid $color-default-font-color;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  z-index: 49;
  border-radius: 0.8rem;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  text-align: start;
  width: 20rem;
  height: auto;
  max-width: 400px;
}

.tooltip-content-info {
  margin-right: 10px;
}

.tooltip-content-wrapper.top {
  bottom: calc(100% + 100%);
  left: 1%;
  transform: translateX(-47%) translateY(10%);
}

.tooltip-content-wrapper.right {
  top: 50%;
  left: 6%;
  transform: translateY(-50%) translateX(30%);
}

.tooltip-content-wrapper.bottom {
  top: calc(100% + 10px);
  left: 0.7%;
  transform: translateX(-35%) translateY(0);
}

.tooltip-content-wrapper.left {
  top: 50%;
  right: calc(100% + 15px);
  transform: translateY(-50%) translateX(0);
}

.tooltip-arrow {
  position: absolute;
  border-style: solid;
  z-index: -1;
  background-color: $color-default-font-color;
}

.tooltip-content-wrapper.top .tooltip-arrow {
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-content-wrapper.right .tooltip-arrow {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.tooltip-content-wrapper.bottom .tooltip-arrow {
  bottom: 100%;
  left: 49%;
  transform: translateX(-50%);
}

.tooltip-content-wrapper.left .tooltip-arrow {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip-arrow::before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: $color-default-font-color;
  transform: rotate(45deg);
  z-index: -1;
}

.tooltip-content-wrapper.top .tooltip-arrow::before {
  top: calc(100% + 90%);
  left: 50%;
  transform: translateX(0) rotate(45deg);
}

.tooltip-content-wrapper.right .tooltip-arrow::before {
  top: 50%;
  left: calc(100% - 8px);
  transform: translateY(-50%) rotate(45deg);
}

.tooltip-content-wrapper.bottom .tooltip-arrow::before {
  top: calc(100% - 8px);
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.tooltip-content-wrapper.left .tooltip-arrow::before {
  top: 50%;
  left: -1rem;
  transform: translateY(-50%) rotate(45deg);
}

.tooltip-close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
