@import "_variables.scss";

.frequency-module-container {
  .frequency-interval-cycle-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .divider-style {
    border: 1px dashed $color-link-hover-color;
  }
  .card-container-description {
    background-color: $color-sidebar-hover-bg;
    margin-top: 0;
    margin-left: 1rem;
    border-radius: 8px;
    padding: var(--chakra-space-9-6);
  }
  .frequency-day-of-month-box {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    .frequency-module-dropdown {
      width: 60%;
      min-width: 26rem;
    }

    p {
      font-size: $font-base-size;
      font-family: $font-family-bold;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: $color-description-txt;
    }
  }
  .description-card-title {
    font-size: 14px;
    font-family: $font-family-regular;
    font-weight: 700;
    margin-bottom: 0.8rem;
  }

  .description-card-paragraph {
    font-size: 14px;
    font-family: $font-family-regular;
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  .card-container-code {
    background-color: $color-sidebar-hover-bg;
    margin-left: 1rem;
    border-radius: 8px;
    margin-top: 1rem;
    height: 31px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    p {
      color: $color-border-blue;
    }
  }

  .frequency-or-text {
    color: $color-font-card;
    font-family: $font-family-regular;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  .frequency-ecm-text {
    color: $color-link-hover-color;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    width: 161px;
  }

  .description-title {
    font-family: $font-family-regular;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1%;
  }

  .text-hightlighted {
    font-weight: 700;
  }
  .frequency-module-dropdown {
    width: 100%;
    .fx-single-select-container {
      margin-bottom: 0;

      .fx-single-select {
        flex: 0 0 100%;
        width: 100%;
        .multiSelectContainer.fx-multiselect-wrapper {
          min-width: 100%;
          .optionListContainer ul {
            width: 100%;
          }
        }
      }
    }
  }
  .frquency-module-business-day-dropdown {
    width: 60%;
    min-width: 26rem;
    .fx-single-select-container {
      margin-bottom: 0;
      .fx-single-select {
        flex: 0 0 100%;
        width: 100%;
        .multiSelectContainer.fx-multiselect-wrapper {
          min-width: 100%;
          .optionListContainer ul {
            width: 100%;
          }
        }
      }
    }
  }
  .frequency-module-value-container {
    .frequency-module-selected-container {
      width: 203px;
      border-radius: 8px;
      .app-tag-selected {
        background-color: $color-sidebar-hover-bg;
        color: $color-link-hover-color;
        text-transform: uppercase;
        span {
          width: 147px;
          font-family: $font-family-regular;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          text-transform: uppercase;
        }
        .outer {
          cursor: pointer;
          width: 16px;
          height: 16px;
          border: 1px;
          background: $color-link-hover-color;
          border-radius: 50%;
          path {
            width: 8px;
            height: 8px;
            color: $color-default-white;
          }
        }
      }
    }
    .frequency-module-edit-btn-group {
      width: 81px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .app-btn-link {
        font-family: $font-family-regular;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }
  }

  .app-modal-content {
    .app-modal-body-container {
      .chakra-modal__body {
        overflow-y: visible;
      }
    }
  }
}

.app-input-with-dropdown-modal {
  width: 500px !important;
}
