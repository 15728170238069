@import "_variables.scss";

.back-title {
  font-family: $font-family-regular;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: $line-height-small;
  letter-spacing: 0.01em;
  text-align: left;
}
