@import "_variables.scss";

.couple-radio-dropdown-container {
  .couple-radio-dropdown {
    flex-direction: column;
  }
  .fx-single-select-container {
    margin-bottom: 0;
    .fx-single-select {
      flex: 0 0 100%;
      width: 100%;
      .multiSelectContainer.fx-single-select-wrapper {
        min-width: 100%;
        .optionListContainer {
          z-index: 10001;
        }
      }
    }
  }
  .couple-radio-label {
    margin-top: var(--chakra-space-6);
  }
}
