@import "_variables.scss";

.multiselect-checkbox {
  max-height: 332px;
  overflow: scroll;
  padding: var(--chakra-space-1);
  border-radius: $border-radius-small;
  border: 1px solid $color-border;
  .custom-table-header-data-item {
    width: 100% !important;
    .custom-table-header-checkbox {
      width: 100%;
      .chakra-checkbox__control {
        border-radius: $border-radius-xs !important;
      }
    }
  }
  .navigation-link {
    display: flex;
    column-gap: var(--chakra-space-3);
    margin-top: var(--chakra-space-7);
  }
}

.icon-button {
  background: none;
}
