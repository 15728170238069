@import "_variables.scss";

.product-configurations {
  font-family: $font-family-regular !important;
  margin: var(--chakra-space-24) var(--chakra-space-40) var(--chakra-space-56)
    var(--chakra-space-60);
  h2 {
    font-size: $font-size-xl;
    font-family: $font-family-semi-bold;
    margin-bottom: var(--chakra-space-6);
  }

  .product-summary-selection-container {
    padding: var(--chakra-space-12-8);
    margin-top: var(--chakra-space-3-5);
    margin-right: var(--chakra-space-4);
  }

  .product-config-small-heading {
    font-size: $font-size-2rem;
    margin-bottom: var(--chakra-space-16);
  }
  .walkthrough-stepper {
    width: 45%;
    .step {
      .step-indicator {
        background-color: $color-default-white;
        border-color: $color-stepper-indicator;
        border-width: 1px;
        color: $color-stepper-indicator;
        width: var(--chakra-space-10);
        height: var(--chakra-space-10);
        .step-number {
          font-size: $font-size-small;
        }
      }
      .step-separator {
        height: 1px;
        background: $color-gradient-light-orange;
        width: var(--chakra-space-8);
        margin: 0;
      }
    }
    .step[data-status="active"] {
      flex-grow: 8;

      .step-indicator[data-status="active"] {
        background-color: $color-default-font-color;
        color: $color-default-white;
        width: var(--chakra-space-16);
        height: var(--chakra-space-16);
        .step-number[data-status="active"] {
          font-size: $font-size-2rem;
          font-weight: 600;
          font-family: $font-family-semi-bold;
        }
      }
      .stepper-title {
        margin-left: calc(var(--chakra-space-16) * -1);
        margin-top: var(--chakra-space-16);
        position: absolute;
        white-space: nowrap;
      }
    }
  }

  .slider-container {
    margin-top: var(--chakra-space-30);
    margin-bottom: calc(var(--chakra-space-12) * -1);
    .product-config-slider {
      .slider-description {
        font-size: $font-size-2rem;
        white-space: pre-line;
      }
    }
  }
}
.configured-product-footer {
  height: $config-form-footer-height;
  background: $color-btn-secondary-hover;
  padding: var(--chakra-space-4) var(--chakra-space-8);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .app-skip-walkthru-btn {
    padding: 14px;
    margin-right: var(--chakra-space-4);
    &:hover {
      text-decoration: underline;
      text-underline-offset: 8px;
    }
  }
}
