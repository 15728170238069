@import "_variables.scss";

.hero-banner-container {
  width: 100%;
  min-height: 110px;
  padding: 20px 30px;
  border-bottom: 1px solid $color-gradient-light-orange;
}
.header-back-ground-image {
  background: transparent
    url("../../../../public/assets/images/header-image.png") no-repeat;
  background-size: cover;
  width: 100%;
  margin-left: 0;
  position: relative;
}
