@import "_variables.scss";

.form-field-msg-container {
  margin-top: 1rem;
  gap: 0.8rem;
  svg {
    font-size: $font-size-reg;
    color: $color-font-card;
  }
  .form-field-msg {
    font-size: $font-size-xs;
  }
}
