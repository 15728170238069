@import "_variables.scss";

.outline-download > button {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  line-height: var(--chakra-space-9-6);
  color: $color-link-hover-color;
  border: 1px solid $color-link-hover-color;
  white-space: wrap;
  @media screen and (max-width: $breakpoint-mobile-desktop){
    width: 100%;
  }
}
