@import "_variables.scss";

.learning-info-card-container {
  position: relative;
  color: $color-font-card;
  border-radius: 6px;
  min-height: 24rem;
  cursor: pointer;
  flex-direction: row;
  padding: 0px;
  height: 100%;

  &:hover {
    background-color: $color-bg-badge;
  }

  .learning-info-card-media-container {
    img {
      object-fit: cover;
      max-width: 20rem;
      height: 100%;
      border-radius: 6px 0px 0px 6px;
    }
  }

  &.learning-info-card-media-direction {
    flex-direction: column;
    img {
      border-radius: 6px 6px 0px 0px;
      max-width: 100% !important;
      display: block;
      width: 100%;
      height: 200px;
      object-fit: fill;
    }
  }

  .learning-info-card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .learning-info-card-title {
    font-size: $font-size-l;
    font-weight: 600;
  }

  .learning-info-card-divider {
    height: 12px;
    width: 1px;
    background: $color-default-black;
    margin-top: 5px;
  }

  .learning-info-card-arrow-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .learning-info-card-arrow-box {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    svg {
      color: $color-link-color;
      border: 1px solid $color-bg-badge;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $color-bg-badge;
    }
  }

  &.disabled-info-card {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .learning-info-card-arrow {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.5rem;
  }
}
