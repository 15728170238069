@import "_variables.scss";

.button-feedback {
  position: fixed;
  gap: var(--chakra-space-2);
  padding: var(--chakra-space-2) var(--chakra-space-3);
  left: 100%;
  top: 70%;
  transform-origin: 0% 100%;
  transform: rotate(270deg);
  border-radius: var(--chakra-space-3) var(--chakra-space-3) 0px 0px;
  border-top: var(--chakra-space-1) solid $color-nav-selected;
  border-left: var(--chakra-space-1) solid $color-nav-selected;
  border-right: var(--chakra-space-1) solid $color-nav-selected;
  background-color: #fff;

  .text {
    font-size: var(--chakra-fontSizes-2xl);
    font-weight: var(--chakra-fontWeights-normal);
    line-height: var(--chakra-lineHeights-8);
    color: $tag-notstarted-text-color;
  }
}

.modal-content {
  max-width: 33%;
  min-width: fit-content;
  background-color: white;

  .grid-modal-content {
    align-items: stretch;
  }
}
