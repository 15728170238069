@import "_variables.scss";

.app-input-dropdown-container {
  margin-bottom: 0rem;
  .app-input-dropdown-fields {
    gap: 1.8rem;

    .app-number-input-wrapper {
      width: 58px;
    }
    .app-input-dropdown {
      width: 168px;
      .fx-single-select-container {
        margin-bottom: 0;
        .fx-single-select {
          flex: 0 0 100%;
          width: 100%;
          .multiSelectContainer.fx-single-select-wrapper {
            min-width: 100%;
            .optionListContainer {
              z-index: 10001;
              ul {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .app-input-dropdown-value-container {
    .app-input-dropdown-selected-container {
      width: 203px;
      border-radius: 8px;
      .app-tag-selected {
        background-color: $color-sidebar-hover-bg;
        color: $color-link-hover-color;
        span {
          width: 147px;
          font-family: $font-family-regular;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          text-transform: uppercase;
        }
        .outer {
          cursor: pointer;
          width: 16px;
          height: 16px;
          border: 1px;
          background: $color-link-hover-color;
          border-radius: 50%;
          path {
            width: 8px;
            height: 8px;
            color: $color-default-white;
          }
        }
      }
    }
    .app-input-dropdown-edit-btn-group {
      width: 81px;
      height: 38px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .app-btn-link {
        font-family: $font-family-regular;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }
  }
}

.app-input-with-dropdown-modal {
  width: 500px !important;
}
.app-input-dropdown-code-chip {
  background-color: $color-sidebar-hover-bg;
  margin-left: var(--chakra-space-px);
  border-radius: 8px;
  margin-top: 1rem;
  height: 31px;
  padding: 7px 12px;
  width: 27%;
  span {
    color: $color-border-blue;
  }
}
