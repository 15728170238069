@import "_variables.scss";

.status-bubble-container {
  width: 2rem;
  padding: 1rem;
  border: 1px solid $color-border;
  border-radius: 12px;
  .img {
    width: auto;
  }
}
