@import "_variables.scss";

.tooltip-icon {
  cursor: pointer;
  position: relative;
  display: inline;
  margin-left: var(--chakra-space-2);
  svg {
    width: 1.6rem;
    height: 1.6rem;
    color: $color-default-font-color;
  }

  .icon-default circle:first-child {
    fill: $color-default-white;
  }
  
  .icon-default {
    color: $color-default-font-color;
    :hover {
      circle:first-child {
        stroke-width: 3px;
      }
      line {
        stroke: $color-default-font-color;
        stroke-width: 3px;
      }
      circle:last-child {
        stroke-width: 1px !important;
        stroke: $color-default-font-color;
        fill: $color-default-font-color;
      }
    }
  }
  
  .icon-deactivated {
    pointer-events: none;
    color: $color-icon-deactivated;
  }
  .icon-deactivated circle:first-child {
    fill: $color-default-white;
  }

  .blue-info-icon {
    circle:first-child {
      fill: $color-border-blue !important;
      stroke: transparent;
    }
    line {
      stroke: $color-default-white;
    }
    circle:last-child {
      fill: $color-default-white;
    }
    :hover {
      circle:first-child {
        fill: $color-border-blue !important;
        stroke: transparent;
      }
      line {
        stroke: $color-default-white;
        stroke-width: inherit;
      }
      circle:last-child {
        fill: $color-default-white;
        stroke-width: 0px !important;
        stroke: $color-default-white;
      }
    }
  }
}

.tooltip-content {
  background-color: $color-default-white;
  color: $color-default-black;
  padding: var(--chakra-space-6-4);
  z-index: 1002;
  box-shadow: 0 0 0.8rem $color-box-shadow-tooltip;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 28rem;
  font-weight: 400;
  height: auto;
  
  &:focus-visible {
    box-shadow: 0 0 0.8rem $color-box-shadow-tooltip;
  }

  .content-text {
    font-size: $font-size-small;
    overflow-wrap: anywhere;
    white-space: pre-line;
    text-align: left;
  }
}


.icon-kebab {
  &:hover {
    cursor: pointer;
    background-color: $tag-style-gray;
  }
}
.circle {
  border: 2px solid $color-gradient-light-orange;
  border-radius: 100%;
  position: relative;
  width: 2rem;
  height: 2rem;

  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out;
}
.circle::before {
  content: "";
  display: block;
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $color-gradient-light-orange;
  border-radius: 100%;

  transition: width, height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.circle:hover {
  width: 1.8rem;
  height: 1.8rem;
}

.circle:focus::before {
  width: 1.5rem;
  height: 1.5rem;
}
