@import "_variables.scss";

.sortable-item {
  width: 40%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  border: 1px solid $color-border;
  border-radius: 8px;
  padding-right: 8px;
  img {
    height: 100%;
    width: auto;
  }
}
