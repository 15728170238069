@import "_variables.scss";

.form-label-container {
  display: flex;
  .form-label {
    font-size: 1.4rem;
    font-family: $font-family-regular;
    font-weight: 600;
    margin-bottom: 0.8rem;
    .form-required {
      font-size: 1.4rem;
      font-weight: 700;
    }

    .tooltip-icon {
      svg {
        width: 2.2rem;
        height: 2.2rem;
        vertical-align: middle;
        margin-bottom: 2.5px;
        color: $color-default-font-color;
        &:hover {
          border: transparent !important;
        }
      }
    }
  }
}
