@import "_variables.scss";
.header-element {
  z-index: 50;
  width: 100%;

  .header-back-ground-image {
    background: transparent
      url("../../../../../public/assets/images/header-image.png") no-repeat;
    background-size: cover;
    position: relative;

    .top-product-container {
      font-size: $font-size-med;
      width: auto;
      border-bottom: 1px solid $color-gradient-light-orange;
      box-shadow: 0px 2px 12px 0px $color-box-shadow-title;
      padding: var(--chakra-space-12-8);
      .top-product-title {
        font-size: $font-size-xl;
        display: flex;
        line-height: $line-height-xxlarge;
        font-weight: $font-weight-semi-bold;
        font-family: $font-family-semi-bold;
        color: $color-default-black;
        max-width: 80%;
      }
      .top-product-sub-title {
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;
        font-family: $font-family-sfmono;
        margin-bottom: var(--chakra-space-4);
        font-size: $font-size-xs;
        color: $color-font-card;
        font-weight: $font-weight-medium;
        line-height: $line-height-xs;
        max-width: 80%;
        text-transform: uppercase;
      }
      .top-product-sub-title-lower {
        font-size: $font-size-med;
        font-weight: $font-weight-regular;
        color: $color-font-card;
      }
      .header-product-button {
        display: flex;
        align-items: center;
        button {
          text-transform: none;
        }
      }
    }
  }
}
