@import "_variables.scss";

.input-text-field-container {
  .chakra-input,
  .chakra-textarea {
    min-height: 48px;
    border: 1px solid $color-form-field-border;
    border-radius: 8px;
    font-size: $font-size-reg;
    width: 100%;
    padding: 0px 1.6rem;
    background-color: $color-default-white !important;
  }
  .chakra-input,
  .chakra-textarea:hover {
    border: 1px solid $color-description-txt;
  }
  .chakra-textarea {
    padding: var(--chakra-space-6);
  }
  .custom-input::placeholder {
    color: $color-form-field-placeholder-txt;
  }
  .custom-input.error {
    border-color: $color-default-danger;
  }
  .show-password-icon {
    width: var(--chakra-space-24);
    height: 100%;
  }
}
