@import "_variables.scss";

.add-new-button {
  display: flex;
  gap: $gap-small;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-small;
  border: var(--chakra-space-px) dashed $tag-inprogress-text-color;
  background: $color-default-white;
  width: 100%;
  padding: var(--chakra-space-6);
  cursor: pointer;
  color: $color-font-card;
  &:hover {
    background-color: $color-sidebar-hover-bg;
    box-shadow: 0 var(--chakra-space-2) var(--chakra-space-6) 0
      $color-box-shadow;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  p {
    font-size: $font-size-14px;
    line-height: $line-height-small;
    font-family: $font-family-semi-bold;
    font-weight: $font-weight-semi-bold;
    letter-spacing: var(--chakra-space-px);
  }
}
