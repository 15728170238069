@import "_variables.scss";

.accordion-component {
  .accordion-item {
    border: none;

    // To prevent dropdown options menu from hiding when placed at bottom of an accordion
    .chakra-collapse {
      overflow: visible !important;
    }
  }

  .accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: var(--chakra-space-6);
    padding-left: var(--chakra-space-4);
  }

  .accordion-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .accordion-title-items {
    height: cal(var(--chakra-space-10) - var(--chakra-space-px));
    display: flex;
    align-items: flex-start;
    text-align: left;
    img {
      width: cal(var(--chakra-space-10) - var(--chakra-space-px));
      height: cal(var(--chakra-space-10) - var(--chakra-space-px));
      margin-left: var(--chakra-space-3);
      cursor: pointer;
    }
    .accordion-delete-img:hover {
      background-color: $color-sidebar-hover-bg;
    }
  }
  .static-text-gray {
    &.accordion-desc {
      margin-left: calc(var(--chakra-space-px) * -1);
      color: $color-font-card;
      font-size: $font-size-small;
    }
  }

  .accordion-title {
    font-family: $font-family-regular;
    font-weight: $font-weight-semi-bold;
    margin-right: -var(--chakra-space-px);
    color: $color-default-font-color;
    margin-left: --var(--chakra-space-px);
    line-height: $line-height-medium;
  }
  .accordion-title-default {
    @extend .accordion-title;
    font-size: $font-size-med;
    line-height: $line-height-medium;
  }
  .accordion-title-20 {
    @extend .accordion-title;
    font-size: $font-size-2rem;
    line-height: $line-height-large;
  }
  .accordion-title-24 {
    @extend .accordion-title;
    font-size: $font-size-l;
    line-height: $line-height-xlarge;
  }
  .accordion-limit-name {
    width: 60%;
    height: var(--chakra-space-8);
    text-align: left;
    font-family: $font-family-regular;
    font-size: $font-size-14px;
    font-weight: $font-weight-regular;
    line-height: $line-height-small;
    color: $color-font-card;
    margin-top: var(--chakra-space-px);
  }

  .accordion-expand {
    height: cal(var(--chakra-space-10) - var(--chakra-space-px));
    width: cal(var(--chakra-space-10) - var(--chakra-space-px));
  }

  .accordion-panel {
    padding: var(--chakra-space-4);
  }
}
