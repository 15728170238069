@import "_variables.scss";

.app-card-container {
  .collapse-expand-accordion-container {
    border: $color-default-white;
    font-size: $font-size-xs;
    font-family: $font-family-regular;
    color: $color-font-card;
    .chakra-icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
