@import "_variables.scss";

.drag-drop-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  .drag-drop-index {
    width: 2rem;
  }
}
