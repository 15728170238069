@import "_variables.scss";

.footer-not-logged-in-container{
    width: 100%;
    min-height: 278px;
    padding: 20px 30px;
    border-top: 3px solid $color-gradient-light-orange;
    position: relative;
    background: rgba(255,255,255,85%) url("../../../../public/assets/images/dot-pattern.svg") no-repeat;
    background-size: cover;
    background-position: -35px -35px;
    display: flex;
    justify-content: center;
    .footer-text{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: $color-default-white;
        height: 220px;
        width: 700px;
        padding: var(--chakra-space-13);
        h2{
            font-size: $font-size-l;
            line-height: $line-height-xlarge;
            font-weight: $font-weight-600;
        }
        p{
            font-size: $font-size-med;
            line-height: $line-height-medium;
            font-weight: $font-weight-400;
            padding: $padding-large 0;
        }
        .footer-buttons{
            display: flex;
            gap: var(--chakra-space-12-8);
            button{
                text-transform: none;
            }
            .footer-button-width{
                width: 300px;
            }
        }
    }
}
