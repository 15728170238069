@import "_variables.scss";

.carousel-button-group {
  button {
    background-color: transparent;
    .chakra-icon {
      height: var(--chakra-space-20);
      width: var(--chakra-space-20);
    }
    &.hidden {
      display: none;
    }
    &:hover {
      background: none;
    }
  }
  .carousel-arrow-left {
    position: absolute;
    top: 365px;
    left: var(--chakra-space-8);
  }
  .carousel-arrow-right {
    position: absolute;
    top: 365px;
    right: var(--chakra-space-8);
  }
}
