@import "_variables.scss";

.chakra-radio-group {
  .radio-button-box {
    width: max-content;
    flex: 1;
  }
  &.radio-box-width-auto {
    .radio-box {
      width: auto;
    }
  }
  .radio-box {
    width: 39%;
    flex: 46%;
    border: 1px solid $color-border;
    border-radius: 8px;
    padding: 7px 16px;
    background-color: $color-default-white;
    &:hover {
      background-color: $tag-notstarted-bg-color;
    }
    &.error {
      border-color: $color-default-danger;
    }
    &.radio-selected {
      border-color: $color-border-blue;
      background-color: $color-sidebar-hover-bg;
    }
    .chakra-radio__label {
      margin-left: 1rem;
    }
    .chakra-radio {
      width: 100% !important;
    }
  }
  .chakra-radio {
    width: max-content;
  }

  .radio-button {
    border: 0.2rem solid $color-font-card;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    &:hover {
      outline: 0.4rem solid $color-radio-button-outline;
      border: 0.2rem solid $color-radio-button-color;
    }
    &.radio-button[data-checked]::before {
      height: 67%;
      width: 67%;
      margin: 0 auto;
    }
  }
  .radio-button[data-disabled]:hover {
    outline: none;
    opacity: 1;
    border: 0.2rem solid $color-font-card;
  }
  .radio-button[data-checked] {
    background-color: $color-default-white;
    color: $color-radio-button-color;
    border: 0.2rem solid $color-radio-button-color;
    &:hover {
      outline: 0.4rem solid $color-radio-button-outline;
      background-color: $color-default-white;
      border: 0.2rem solid $color-radio-button-color;
    }
  }

  //disabled radio
  .radio-button-disabled[data-disabled] {
    border-radius: 1.75rem;
    border: 0.2rem solid $color-default-black;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  .radio-button-disabled[data-disabled]:hover {
    outline: none;
  }
  .radio-button-disabled[data-disabled][data-checked] {
    pointer-events: none;
    color: $color-radio-button-color;
    border: 0.2rem solid $color-radio-button-color;
    cursor: not-allowed;
  }

  .chakra-radio__label {
    font-size: $font-size-small;
    font-weight: 400;
  }
}
