@import "_variables.scss";
.sortable-element {
  width: 100%;
  display: block;
  margin-top: 1rem;
  border: 1px solid $color-border;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  .icon-container {
    display: flex;
    position: absolute;
    align-items: center;
    top: 0;
    right: -5px;
    margin-bottom: 10px;
    .sort-icon {
      height: 40px;
      margin-left: 10px;
    }
    img {
      cursor: pointer;
    }
  }
}
