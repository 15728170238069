.image-styling-flyout {
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-self: center;
}
.image-title-styling-flyout {
  font-size: smaller;
  align-self: center;
  .image-styling-flyout {
    width: 60%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .image-title-styling-flyout {
    font-size: smaller;
    align-self: center;
  }
}
