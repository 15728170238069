@import "_variables.scss";
.range-slider-custom {
  height: 0.6rem;
  background-color: rgb(228, 225, 225);
}
.number-slider-text {
  justify-content: space-between;
  width: 33rem;
  margin-top: 0px;
}
.number-color {
  color: $color-description-txt;
  text-align: right;
  /* DETAIL S */
  font-family: SF Mono;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  letter-spacing: -0.07px;
  text-transform: uppercase;
}
.flex-slider-item {
  flex-direction: column;
  align-items: center;
  margin-left: 1.8rem;
  margin-top: 1.9rem;
}
.range-slider-outer-outer-text {
  flex-direction: column;
  align-items: flex-start;
}
.title-range-slider {
  color: $color-font-card;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  margin-right: 1rem;
}
.info-img-range-slider {
  margin-top: 0.35rem;
}
.range-slider-outer-inner-text {
  margin-bottom: 2rem;
}
.range-slider-outer-inner-text {
  flex-direction: row;
}
.outer-slider-flex {
  flex-direction: row;
  align-items: center;
}
.range-slider-thumb-css {
  border: 3px solid $color-default-white;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.9rem;
  background-color: $color-nav-selected;
}
.slider-second-tooltip {
  position: relative;
  background-color: $color-default-black;
  border: 2px solid $color-default-black;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.6rem;

  &::before {
    content: "";
    position: absolute;
    margin-bottom: 0.3rem;
    bottom: -10px; /* Adjust as needed to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 9px 6px 0px 6px; /* Adjust the border-width to control the size of the arrow */
    border-style: solid;
    border-color: #000 transparent transparent transparent; /* Arrow color */
  }

  /* Additional styling for the tooltip content */
  color: $color-default-white;
  padding: 0.2rem var(--chakra-space-2);
}

.first-slider-thumb {
  border: 3px solid $color-default-white;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.9rem;
  background-color: $color-nav-selected;
}
.range-slider-value-custom {
  width: 33rem;
  height: 5rem;
}
.range-slider-filled-track {
  background-color: $color-nav-selected;
}
