@import "../../../styles/variables";
.chakra-slide {
  max-width: 46.9rem;
  max-height: 58rem;
  width: 32% !important;
  margin-top: var(--chakra-space-80);
  background-color: $color-sidebar-hover-bg;
  padding: var(--chakra-space-9-6);
  border-radius: 16px 0 0 16px;
}
.chakra-modal__body {
  padding-right: var(--chakra-space-6-4);
  margin-top: var(--chakra-space-6-4);
  overflow-y: auto;
}
.drawer-content-flyout {
  position: absolute;
  .chakra-icon {
    width: 13.15px;
    height: 13.15px;
  }
  .chakra-modal__close-btn {
    top: var(--chakra-space-9-6);
    right: var(--chakra-space-9-6);
  }
  .chakra-modal__close-btn:hover {
    color: $color-link-hover-color;
  }
  .css-vri8nb:hover {
    --close-button-bg: none;
  }
  .chakra-tabs__tablist button[aria-selected="true"] {
    border-bottom: transparent;
    font-size: $font-size-small;
  }
  .chakra-tabs__tablist button {
    border-bottom: 1px solid $color-border;
    font-size: $font-size-small;
  }
}
.flyout-container {
  width: calc(var(--chakra-space-34) - var(--chakra-space-4));
  background: $color-sidebar-hover-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px 0 0 16px;
  position: fixed;
  right: 0;
  top: var(--chakra-space-100);
  .flyout {
    margin: calc(var(--chakra-space-5) - calc(var(--chakra-space-2) / 10));
    display: flex;
    flex-direction: column;
    cursor: pointer;
    img {
      width: calc(var(--chakra-space-11) - calc(var(--chakra-space-4) / 10));
      height: calc(var(--chakra-space-11) - calc(var(--chakra-space-4) / 10));
      margin-bottom: 4px;
    }
    &:last-child {
      img {
        width: calc(var(--chakra-space-10) - calc(var(--chakra-space-4) / 10));
        height: calc(var(--chakra-space-10) - calc(var(--chakra-space-4) / 10));
      }
    }
  }
}
.menu-list-container {
  width: var(--chakra-space-48);
  overflow-y: auto;
  box-shadow: $box-shadow-default;
  border-radius: var(--chakra-space-8);
  padding: var(--chakra-space-2-4) var(--chakra-space-4);
  position: relative;
  top: calc(var(--chakra-space-2) * -1);
  left: var(--chakra-space-4);
  .dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: var(--chakra-space-17);
    gap: var(--chakra-space-4);
    color: $color-default-font-color;
    font-family: $font-family-semi-bold;
    font-size: $font-size-reg;
    font-weight: $font-weight-700;
    line-height: $line-height-xs;
    &.selected {
      cursor: auto;
      color: $color-surface-text-blue;
    }
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
}
.flyout-header {
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  line-break: auto;
  width: 90%;
  &.chakra-button {
    background: none;
    padding: 0;
    width: auto;
    .chakra-icon {
      height: var(--chakra-space-8);
      width: var(--chakra-space-8);
    }
  }
}
.flyout-subheader {
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
}
.docs-tab-subhead {
  margin-bottom: var(--chakra-space-6-4);
  font-size: $font-size-med;
  font-weight: $font-weight-regular;
}
.doc-list-card {
  background: $color-default-white;
  border-radius: $border-radius-small;
  padding: calc(var(--chakra-space-4) + var(--chakra-space-1));
  margin-bottom: var(--chakra-space-6-4);
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-box-default;

  .doc-list-card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: calc(
      var(--chakra-space-2) + calc(var(--chakra-space-4) / 10)
    );
    font-size: $font-size-small;
    font-weight: $font-weight-semi-bold;
  }
  .doc-card-end {
    display: flex;
    gap: var(--chakra-space-6-4);
    font-family: $font-family-sfmono;
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
    .arrow-link:hover {
      content: url("../../../../public/assets/images/arrow_hover.svg");
    }
    .doc-card-caption {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
    }
  }
  iframe {
    width: 100%;
    height: auto;
    border-radius: $border-radius-small;
  }
}
.flyouts-overlay {
  background: transparent;
}
.api-flyout-container {
  .api-list {
    background-color: $color-bg-badge;
    color: $color-default-black;
    font-size: $font-size-reg;
    font-weight: $font-weight-700;
    line-height: $line-height-medium;
    padding: var(--chakra-space-4);
    border-radius: $border-radius-small $border-radius-small 0 0;
    margin-top: calc(var(--chakra-space-6) - calc(var(--chakra-space-4) / 10));

    &.flex-acenter {
      margin-top: 0;
    }
  }

  .fx-accordion {
    background-color: $color-default-white;
    border-bottom: 1px solid $color-border;
    padding: var(--chakra-space-8-8) 0;
    .api-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .api-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    .chakra-accordion__button[aria-expanded="true"] + a span {
      color: $color-link-hover-color;
    }
    .api-container {
      display: flex;
      flex-direction: row;
      width: fit-content;
      .fx-accordion-icon {
        margin-right: 0;
      }
    }
    .api-card-end {
      display: flex;
      gap: var(--chakra-space-6-4);
      .api-card-buttons {
        background: none;
      }
      .api-card-buttons:hover {
        background: $color-sidebar-hover-bg;
        color: $color-link-hover-color;
      }
    }
  }
  .fx-accordion-icon {
    margin-right: calc(
      var(--chakra-space-5) - calc(var(--chakra-space-2) / 10)
    );
    color: $color-link-hover-color;
    height: var(--chakra-space-8);
    width: var(--chakra-space-8);
  }
  .api-name {
    font-size: $font-size-small;
    line-height: var(--chakra-space-8);
    font-weight: $font-weight-semi-bold;
    color: $color-default-font-color;
  }
  .api-container[aria-expanded="true"] {
    .fx-accordion-icon {
      color: $color-surface-text-blue;
    }
  }
  .accordian-description {
    background: $color-default-white;
    border-top: 1px solid $color-gradient-light-orange;
    padding: var(--chakra-space-5) var(--chakra-space-16);
  }
}
.video-separator {
  padding-bottom: var(--chakra-space-6-4);
  border-bottom: 1px solid $color-description-txt;
}
.doc-link-tab {
  .doc-bottom-link {
    margin-top: var(--chakra-space-5-6);
    margin-bottom: var(--chakra-space-21-6);
  }
}
