@import "variables.scss";

.container {
  height: calc(100vh - $app-header-height);
  grid-template-rows: auto 1fr;
  .body {
    overflow: auto;
    padding-top: var(--chakra-space-12-8);
  }
}
