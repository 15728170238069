@import "_variables.scss";

.qualtrics-container {
  border-top: 1px solid $color-gradient-light-orange;
  margin-top: var(--chakra-space-25-6);
  height: auto;
  @media screen and (max-width: $breakpoint-mobile-desktop) {
    margin: 0 var(--chakra-space-6-4);
    margin-top: var(--chakra-space-12-8);
  }
  .qualtrics-widget-inline {
    min-height: var(--chakra-space-168);
    width: 100%;

    &.academy {
      height: 422px !important;
      border-top: 1px solid $color-nav-selected;
    }
  }
}
