@import "_variables.scss";
.tag-style {
  background-color: $color-user-tag-color;
  color: $color-link-color;
  width: 11rem;
  height: 4rem;
  border-radius: 2.5rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
}
.profile-tag {
  color: $color-default-black;
  margin-left: 0.7rem;
  font-weight: bold;
  height: 2rem;
  line-height: 3rem;
  font-size: 2.2rem;
  margin-bottom: 2rem;
}
.share-user-widget {
  color: $color-link-color;
  font-weight: bold;
  font-size: 1.8rem;
  margin-top: 5rem;
}
.right-icon-color {
  color: blue;
  font-size: 1.8rem;
  margin-top: 5.4rem;
}
