@import "_variables.scss";

.fx-select-container {
  min-width: 160px;
  .clear {
    cursor: pointer;
    display: inline-block;
    margin-top: var(--chakra-space-5-6);
    font-size: $font-size-small;
    font-weight: $font-weight-semi-bold;
    line-height: var(--chakra-space-8);
    color: $color-link-hover-color;
  }
  .select-control {
    height: $select-control-height;
    font-size: $font-size-reg;
    border: 1px solid;
    border-color: $fast-gray-2;
    border-radius: 8px;
    padding-left: var(--chakra-space-6-4);
    box-shadow: none !important;
    background-color: transparent;
    &.error {
      border-color: $color-default-danger;
    }
    &.menu-open {
      border-color: $fast-blue-2 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $fast-blue-4;
    }
  }

  .menu {
    border-radius: 0 0 8px 8px;
    border: 1px solid $fast-blue-2;
    border-top-width: 0;
    margin-top: 0;
    box-shadow: 0 4px 14px rgb(0 0 0 / 0.3);
    clip-path: inset(0 -2rem -3rem -2rem);
    z-index: 11;
    .menu-header {
      display: flex;
      align-items: center;
      height: $select-option-min-height;
      font-size: $font-size-reg;
      font-weight: bold;
      position: sticky;
      top: 0;
      background: white;
      padding: 0 var(--chakra-space-9-6);
      box-shadow: 0px 0px 4px 0px #0000001a;
      z-index: 1;
    }
    .menu-list {
      background: white;
      margin-top: 0;
      padding-left: var(--chakra-space-6-4);
      padding-right: var(--chakra-space-6-4);
      padding-top: 0;
      box-shadow: none;
      margin-top: var(--chakra-space-4);
      border-radius: 8px;
      &.multi-select {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.with-description {
        margin-top: 0;
      }
      .select-option {
        display: flex;
        align-items: center;
        color: inherit !important; //check
        font-size: $font-size-reg;
        min-height: $select-option-min-height;
        border-radius: 4px;
        padding: 0 var(--chakra-space-3-2);
        margin-bottom: var(--chakra-space-4);
        background-color: transparent;
        &.selected {
          font-weight: bold;
        }
        &:hover,
        &.selected {
          background-color: $fast-blue-4;
        }
        .view-link {
          display: flex;
          align-items: center;
          gap: var(--chakra-space-2);
          font-weight: bold;
          color: $fast-blue-2;
        }
      }
    }
    .clear-all-container {
      display: flex;
      align-items: center;
      padding: 0 var(--chakra-space-9-6);
      box-shadow: 0px 0px 4px 0px #0000001a;
      text-decoration: underline;
      position: sticky;
      bottom: 0;
      height: $select-option-min-height;
      background: white;
      color: $fast-gray-2;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: 12;
    }
  }
}
.fx-selected-values-container {
  margin-top: var(--chakra-space-6);
  .selected-options {
    .tag-container {
      display: flex;
      flex-wrap: wrap;
      row-gap: var(--chakra-space-4);
    }
    .app-tag-selected {
      background-color: $color-sidebar-hover-bg;
      color: $color-link-hover-color;
    }
    .clear {
      cursor: pointer;
      display: inline-block;
      margin-top: var(--chakra-space-5-6);
      font-size: $font-size-small;
      font-weight: 600;
      color: $color-link-hover-color;
    }
  }
}
