@import "_variables.scss";

.toast-container {
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
  width: 48rem;
  padding: 2rem;
  background-color: currentColor;
  border-bottom: 8px solid;
  box-shadow: 0px 4px 14px 0px $color-toast-box-shadow;

  &.error {
    background-color: $color-error-bg;
    border-color: $color-default-danger;
  }
  &.success {
    background-color: $color-toast-success;
    border-color: $color-toast-success-highlight;
  }
  &.info {
    background-color: $color-toast-info-notification;
    border-color: $color-stepper-indicator;
  }
  &.message {
    background-color: $color-toast-message-notification;
    border-color: $color-link-hover-color;
  }
  &.progress {
    background-color: $color-yellow-toast;
    border-color: $color-stepper-status-inprogress;
  }
  &.warning {
    background-color: $color-default-white;
    border-color: $color-stepper-status-inprogress;
  }

  .statusIcon {
    color: currentColor;
    svg {
      width: 20px;
      height: 20px;
    }

    &.error {
      color: $color-default-danger;
    }
    &.success {
      color: $color-toast-success-highlight;
    }
    &.info {
      color: $color-stepper-indicator;
    }
    &.message {
      color: $color-link-hover-color;
    }
    &.progress {
      color: $color-stepper-status-inprogress;
    }
    &.warning {
      color: $color-stepper-status-inprogress;
    }
  }

  .message {
    flex: 1;
  }

  .close-icon {
    cursor: pointer;
  }
}
