@import "_variables.scss";

.split-button {
  height: 36px;
  padding: 1rem 3rem;
  font-size: $font-size-xs;
  font-family: $font-family-regular;
  border-radius: 0.8rem;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid $color-link-hover-color;
}

.split-button:disabled {
  opacity: 1;
}

.split-button-left {
  @extend .split-button;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.split-button-right {
  @extend .split-button;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.split-button-left:active {
  background: $color-link-hover-color;
  color: $color-default-white;
}

.split-button-right:active {
  background: $color-link-hover-color;
  color: $color-default-white;
}

.activeBtn {
  background: $color-link-hover-color;
}

.activeBtn:hover, .activeBtn:disabled:hover {
  background: $color-link-hover-color;
}
