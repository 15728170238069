@import "_variables.scss";

.code-container {
  width: 100%;

  .cm-theme {
    font-family: $font-family-sfmono;
    font-size: $font-size-reg;
    font-weight: $font-weight-regular;
    line-height: $line-height-medium;

    .cm-editor {
      background-color: $color-default-font-color;
      border-left: 1px;
      border-bottom: 1px;
      border-right: 1px;
      border-color: $color-link-hover-color;
      border-bottom-left-radius: var(--chakra-space-3-2);
      border-bottom-right-radius: var(--chakra-space-3-2);
      color: $color-default-white;

      .cm-gutters {
        background-color: $color-default-font-color;
        border-bottom-left-radius: var(--chakra-space-3-2);
        color: $color-default-white;
      }

      .cm-scroller {
        padding: var(--chakra-space-4) 0px var(--chakra-space-9-6) 0px;
      }

    }
  }

  .head {
    border-top: 1px;
    border-right: 1px;
    border-bottom: 0px;
    border-left: 1px;
    border-style: solid;
    background-color: $color-sidebar-hover-bg;
    border-color: $color-link-hover-color;
    border-radius: var(--chakra-space-3-2) var(--chakra-space-3-2) 0px 0px;
    width: 100%;
    padding: var(--chakra-space-1-6) var(--chakra-space-6-4);
    font-size: $font-size-reg;
    .heading {
      font-weight: var(--chakra-fontWeights-semibold);
      font-size: $font-size-reg;
      @media (max-width: $breakpoint-mobile-desktop) {
        clear: both;
      }
    }
    .language {
      font-weight: var(--chakra-fontWeights-normal);
      padding-right: 10px;
    }
    .copy-btn,
    .download-btn {
      background: none;
      &:hover {
        background-color: $color-btn-secondary-hover;
      }
    }
    @media (max-width: $breakpoint-mobile-desktop) {
      display: flex;
      flex-direction: column; // Stacks children vertically
      align-items: flex-start; // Aligns children to the start
      .heading {
        clear: both;
      }
    }
  }

  .cm-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: var(--chakra-space-12-8);
  }
}
