@import "_variables.scss";

.academy-course-data-container {

    .course-title {
        color: $color-default-black;
        font-family: $font-family-bold;
        font-size: $font-size-l;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-xlarge;
    }

    .course-description {
        width: Fill (421px);
        gap: var(--chakra-space-2);
    }

    .player-box {
        margin-left: calc(-1 * var(--chakra-space-12));
        width: 110%;
        overflow-y: hidden;
    }

    .content-title {
        font-family: $font-family-semi-bold;
        font-size: $font-size-med;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-large;
        letter-spacing: 0em;
        text-align: left;
        margin-top: var(--chakra-space-6-4);
        margin-bottom: -$gap-small;
        margin-left: var(--chakra-space-12-8);
    }

    .back-btn {
        background: none;
        padding: 0;
        margin-right: var(--chakra-space-4);
        .chakra-icon{
            height: var(--chakra-space-9);
            width: var(--chakra-space-9);
        }
      }
}
