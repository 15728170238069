@import "_variables.scss";
.stepper-icon {
  background-color: $color-toast-success-notification;
}
.stepper-icon[data-status="complete"] {
  background-color: $color-toast-success-notification;
}
.chakra-step__indicator[data-status="complete"] {
  background-color: $color-toast-success-notification;
}
.chakra-step__indicator[data-status="active"] {
  border-color: $color-stepper-active-tag;
  width: 2.4rem;
  height: 2.4rem;
}
.chakra-step__separator {
  background: linear-gradient(
    to right,
    $color-stepper-dotted-lines 60%,
    transparent 40%
  );
  background-size: 0.4rem 0.1rem;
  flex-grow: 1;
  height: 0.1rem;
  margin: 0 1rem;
}
.chakra-step__separator[data-status="complete"] {
  border: none;
}
.stepper-stying-horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.step-seprator {
  align-self: flex-start;
  margin: 1rem 1rem;
}
.chakra-stepper {
  gap: var(--chakra-space-0);
}
.step-seprator[data-orientation="horizontal"] {
  margin-inline-start: var(--chakra-space-0);
  margin-inline-end: var(--chakra-space-0);
}
.chakra-step {
  gap: var(--chakra-space-0);
}
.stepper-vertical {
  height: 40rem;
}
.stepper-stying-vertical {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}
.step-indicator-vertical {
  margin-right: 1rem;
}
.step-title-vertical {
  padding-top: 0.4rem;
}
.step-seprator-vertical {
  background: linear-gradient(
    to bottom,
    transparent 0.1rem,
    $color-default-black 0.1rem,
    $color-default-black 0.2rem,
    transparent 0.2rem
  );
  background-size: 100% 0.5rem;
  background-position: center center;
  position: relative;
}
.step-title-horizontal {
  position: absolute;
  display: inline-block;
  margin-top: 2.5rem;
  max-width: 40rem;
  white-space: nowrap;
}
.chakra-step__indicator[data-status="incomplete"] {
  border-color: var(--chakra-colors-gray-200);
}
.stepper-width-sizing1 {
  margin-top: 2rem;
  width: 75.5%;
}
.stepper-width-sizing2 {
  margin-top: 2rem;
  width: 51.5%;
}
.stepper-width-sizing3 {
  margin-top: 2rem;
  width: 27%;
}
