@import "_variables.scss";

.drawer-box-container {
  .chakra-modal__content-container {
    z-index: 80;
  }

  .chakra-slide {
    min-width: fit-content !important;
  }
}

.configuration-container .main-container-side-menu.side-menu-open {
  position: fixed;
  overflow-y: auto;
}

.main-container-side-menu {
  height: 100%;
  width: $multi-tiered-side-menu-max-width-px;
  box-shadow: $box-shadow-default;
  transition:
    width 0.4s linear,
    box-shadow 0.4s linear;
  overflow-y: auto;
  background-color: $color-default-white;
  z-index: 10001;

  &.side-menu-open {
    width: $multi-tiered-side-menu-max-width-px;
    flex-shrink: 0;

    @media only screen and ((max-device-width: 640px) or (max-device-width: 767px)) {
      width: 100vw;
      max-width: 100vw;
    }
  }

  &.side-menu-closed {
    position: fixed;
    width: var(--chakra-space-12-8);
    box-shadow: $box-shadow-default;

    .main-container-item {
      align-items: center;
      padding: 0px;
    }
  }

  .container-first-column {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    border-color: $color-link-hover-color;
    border-right-width: 0px;
    z-index: 100;
    position: relative;
    background-color: $color-default-white;
    margin-top: var(--chakra-space-6-4);

    &.isFooter {
      height: calc(100% + var(--chakra-space-100));
    }

    &.selected {
      border-right-width: 1px;
    }

    .side-bar-menu-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: var(--chakra-space-36);
      font-size: $font-size-xs;
      padding: var(--chakra-space-12) var(--chakra-space-6-4);
      font-weight: $font-weight-regular;
      color: $color-btn-link-hover;
      background-color: $color-default-white;
      width: 234px;
      position: fixed;
      transition: width 0.4s ease-in;

      &.collapsed {
        visibility: hidden;
        width: var(--chakra-space-12-8);
      }
    }

    .chakra-accordion {
      width: 100%;

      .chakra-accordion__button {
        margin-top: var(--chakra-space-4);

        .drop-down-icon {
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
          transition: transform 0.3s linear;
          margin-right: var(--chakra-space-4);

          &.rotate-expanded {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
          }
        }
      }

      h6,
      .chakra-accordion__button {
        width: 100%;
        font-size: $font-size-xs !important;
        font-family: $font-family-sfmono !important;
        font-weight: $font-weight-bold;
        color: $color-btn-link-hover;
        padding: var(--chakra-space-1-6) var(--chakra-space-6);
        text-transform: uppercase;
      }

      .separator {
        border-color: $color-gradient-light-orange;
        opacity: 1;
        width: 100%;
        padding-top: $padding-large;
      }

      .chakra-accordion__button {
        padding: calc(var(--chakra-space-4)-var(--chakra-space-1-6)) 0 calc(var(--chakra-space-4)-var(--chakra-space-1-6)) 0;
      }

      .chakra-accordion__panel {
        padding: 0;
      }

      .chakra-accordion__item {
        border: 0;
      }

      .main-menu-list ul {
        padding: {
          top: calc(var(--chakra-space-4)-var(--chakra-space-1-6));
          bottom: calc(var(--chakra-space-4)-var(--chakra-space-1-6));
        }

        li {
          font-size: $font-size-small;
          padding: var(--chakra-space-3-2) var(--chakra-space-6);
          margin-top: 0;

          svg,
          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.drawer-content {
  max-height: 100%;
  margin-top: 60px;
  background-color: $color-default-white;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
  background-color: transparent;
  z-index: 100;

  .drawer-outer-container-body {
    background-color: $color-default-white !important;
  }

  .drawer-body {
    padding: 0px;
    margin-top: 3px;
    background-color: $color-default-white;
    animation: slideIn 0.5 ease-in;
    box-shadow: $box-shadow-default;

    .grid-content {
      display: flex;
      flex-direction: row;
      height: auto;
      background-color: transparent;
      z-index: 50;

      .grid-item {
        border-right-width: 0px;
        border-right-color: $color-link-hover-color;
        padding: 0px;
        padding-top: $padding-xl;
        min-height: calc(100vh - var(--chakra-space-3));
        background-color: white;
        width: $multi-tiered-side-menu-max-width-px;
        min-width: $multi-tiered-side-menu-max-width-px !important;

        &.enable-right-border {
          border-right-width: 1px;
        }

        .grid-data {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}