@import "_variables.scss";

.embeddable-player-container {
  width: 90%;
  position: relative;
  top: var(--chakra-space-12-8);
  left: var(--chakra-space-12-8);
  border-radius: $border-radius-medium;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: calc((514 / 789) * 100%);
  }

  iframe {
    position: absolute;
    top: -47px;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}