@import "_variables.scss";

.common-title {
  .common-version-text {
    height: 16px;
    font-family: $font-family-sfmono;
    font-weight: $font-weight-medium;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    text-transform: uppercase;
    letter-spacing: 0em;
    text-align: left;
    color: $color-version-subtext;
    margin-bottom: var(--chakra-space-3-5);
  }

  .common-main-title {
    height: auto;
    font-family: $font-family-regular;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-l;
    line-height: var(--chakra-space-12-8);
    letter-spacing: 0em;
    text-align: left;
    color: $color-main-title;
  }

  .common-sub-text {
    font-family: $font-family-regular;
    font-weight: $font-weight-regular;
    font-size: $font-size-reg;
    line-height: var(--chakra-space-9-6);
    letter-spacing: 0em;
    text-align: left;
    color: $color-version-subtext;
    margin-top: var(--chakra-space-3-2);
  }
}
