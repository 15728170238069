@import "_variables.scss";

.icon-link-button {
    padding: 0 var(--chakra-space-1-5);
    border-bottom: 1px solid transparent; /* Set initial border so on hover jumping of text doesn;t happen */

    &:hover {
        border-bottom: 1px solid $color-link-hover-color;
        ;
        button {
            color: $color-btn-link-hover;
            text-decoration: none;
        }
    }
    button {
        color: $color-link-hover-color;
        text-decoration: none;
        background: transparent;
        font-size: 1.6rem;
        font-weight: 700;
        white-space: normal;
        word-break: break-word;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
        justify-content: flex-start;
    }
}
