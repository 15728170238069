@import "_variables.scss";
.container {
  min-width: 200px;
  max-width: 600px;
  background-color: $color-default-font-color;
  border-radius: $border-radius-medium;
  display: flex;
  flex-direction: column;
  row-gap: $gap-small;
  padding-bottom: var(--chakra-space-8);

  .button-icon {
    font-size: $font-size-32;
  }
  .guided-tooltip-container {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: $gap-xs;
    padding: var(--chakra-space-12);
    color: $color-default-white;
    font-size: $font-size-small;
    padding-bottom: 17px;

    .button-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .back-button {
        background-color: $color-default-font-color !important;
        padding: var(--chakra-space-8);
        color: $color-default-white;
        border: 1px solid $color-default-white;
        border-radius: $border-radius-small;
        font-weight: bold;
        font-size: $font-size-reg;
        min-width: 85px;
        height: 46px;
      }
      .next-button {
        background-color: $color-default-white;
        color: $color-default-font-color;
        min-width: 125px;
        border-radius: $border-radius-small;
        font-weight: bold;
        font-size: $font-size-reg;
        height: 48px;
        padding: var(--chakra-space-5-6) var(--chakra-space-9-6);
        div {
          display: flex;
          column-gap: $gap-xs;
          font-weight: bold !important;
          font-size: $font-size-reg !important;
        }
        img {
          transform: rotate(270deg);
        }
      }
    }
    .close-icon-item {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      color: --color-white;
    }
    .tooltip-container-content{
      white-space: pre-line;
    }
  }
  .title {
    font-weight: bold;
    font-size: $font-size-reg;
  }
  .progress-bar {
    background-color: $color-font-card;
  }
}

.__floater__open {
  top: 18px !important;
  left: -35px !important;
}
.react-joyride__spotlight {
  background-color: transparent !important;
}
.__floater__arrow {
  left: 33px !important;
}

#react-joyride-step-1 {
  .__floater__open {
    top: 65px !important;
    left: 0px !important;
  }
  .__floater__arrow {
    left: 0px !important;
  }
}
